'use client';

import Link from 'next/link';
import Lottie from 'lottie-react';
import { Button } from '@/components/ui/button';
import animationData from '@/public/images/lotties/404.json';

export default function NotFound() {
  return (
    <div className="flex flex-col items-center min-h-screen text-center">
      <div className="size-[500px] dark:invert">
        <Lottie animationData={animationData} loop={true} />
      </div>
      <p className="text-xl mb-8 text-muted-foreground">
        Oups ! La page que vous recherchez semble avoir disparu.
      </p>
      <Button asChild>
        <Link href="/">Retour à l&apos;accueil</Link>
      </Button>
    </div>
  );
}
